import {
  Box
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import { Button, Table } from "reactstrap";
import loader from "../../../assets/images/loading_wbg.gif";
import { default as ApiEndPoint, default as apiEndPoints } from "../../../utils/apiEndPoints";
import {
  AllImages,
  BhaktiPadPlaylistShareUrl,
  GLOBAL_URL,
  RemoveDuplicates,
  VideoPlaylistShareUrl,
  emojiRegex
} from "../../../utils/constants";
import { apiCall } from "../../../utils/httpClient";
import CommonCarousal from "../../common/Carousal/CommonCarousal";
import {
  ActiveTabsContext,
  AlbumFavContext,
  AudiosFavoriteListContext,
  CurrentTrackIndex,
  EBooksFavoriteListContext,
  EBooksSeriesFavouriteListContext,
  FavoritesPlayListContext,
  FavoritesVideoListContext,
  GetDowloadedEbooksContext,
  LoaderContext,
  MusicPlayerVisibilityContext,
  MusicTrackContext,
  MusicTracksContext,
  PlayNextContext,
  PlayingAlbumIdContext,
  PlayingShuffledAlbumIdContext,
  QuestionHistoryContext,
  SharedPlayListContext,
  ShowCreatePlayListContext,
  UserPlayListContext,
  UserloggedContext,
  userDetailsContext
} from "../../Context/UserContext";
import CreatedPlaylist from "../CreatedPlaylist";
import DownloadedEbooks from "../DownloadedEbooks";
import QuestionHistory from "../QuestionHistory";
import SharedPlaylist from "../SharedPlaylist";

import "./style.css";
import { Google } from "@mui/icons-material";
import { confirmAlert } from "react-confirm-alert";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import { useDispatch, useSelector } from 'react-redux';
import { addTrack } from "actions/Index";

function MySpace() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const playlistTracks = useSelector(state => state.tempPlaylist.playlistTracks); // Use playlistTracks
  const [activetabs, setActiveTabs] = useContext(ActiveTabsContext);
  const { track, setTrack } = useContext(MusicTracksContext);
  const { currentTrackIndex, setCurrentTrackIndex } = useContext(CurrentTrackIndex);

  const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
  const { openRecaptcha, recaptchaFun, onChange } = useGoogleLoginFlow();
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [tabValue, setTabValue] = React.useState(activetabs.filter((item) => item.page == "MySpace")[0].active);
  const [tabValueForCreatePlaylist, setTabValueForCreatePlaylist] = useState('videos');
  const [auth, setAuth] = useContext(UserloggedContext);
  const [favoritesVideoList, setFavoritesVideoList] = useContext(FavoritesVideoListContext);
  const [favoritePlayListData, setFavoritePlayListData] = useContext(FavoritesPlayListContext);

  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(
    MusicPlayerVisibilityContext
  );

  const [eBooksFavoriteList, setEBooksFavoriteList] = useContext(
    EBooksFavoriteListContext
  );
  const [audiosFavoriteList, setAudiosFavoriteList] = useContext(AudiosFavoriteListContext);
  const [eBookSeriesFavoriteList, setEBookSeriesFavoriteList] = useContext(
    EBooksSeriesFavouriteListContext
  );
  const [getdownloadedEBook, setGetDownloadedEbook] = useContext(
    GetDowloadedEbooksContext
  );
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(
    ShowCreatePlayListContext
  );
  const [questionHistoryList, setQuestionHistoryList] = useContext(
    QuestionHistoryContext
  );
  const [pageNo, setPageNo] = useState(0); // State for page number
  const [scrollHashMap, setScrollHashMap] = useState(false);
  const [scrollLast, setScrollLast] = useState(true);



  const [isOpen, setIsOpen] = useState(false);
  const [addToPlaylistFlagFromFavorites, setAddToPlaylistFlagFromFavorites] = useState(false);
  const [userPlayList, setUserPlayList] = useContext(UserPlayListContext);
  const [sharedPlaylist, setSharedPlayList] = useContext(SharedPlayListContext);
  const [albumFavList, setAlbumFavList] = useContext(AlbumFavContext);
  const [isLoading, setIsLoading] = useContext(LoaderContext);
  const [refresh, setRefresh] = useState(false);
  const [sharedPlaylistCount, setSharedPlaylistCount] = useState(0);
  const [playlistTab, setPlaylistTab] = useState('videos');
  const [playlistName, setPlaylistName] = useState("");
  const [BtnLoading, setBtnLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);


  const [unselectedPlaylistArray, setUnselectedPlaylistArray] = useState([]);
  const [currentlyPlaylingVidId, setCurrentlyPlaylingVidId] = useState();
  const [
    previouslySelectedUserPlaylistArray,
    setPreviouslySelectedUserPlaylistArray,
  ] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [
    currentlySelectedUserPlaylistArray,
    setCurrentlylySelectedUserPlaylistArray,
  ] = useState([]);
  const [isValidPlaylistName, setIsValidPlaylistName] = useState(false);
  const [isValidNote, setIsValidNote] = useState(false);
  const [createPlaylistBtnLding, setPlaylistBtnLding] = useState(false);
  const [createNoteBtnLding, setNoteBtnLding] = useState(false);
  const [showSaveInfo, setShowSaveInfo] = useState(false);
  const [noteTitle, setNoteTitle] = useState("Shabdarth");
  const [note, setNote] = useState(null);
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState(null);
  const [video, setVideo] = useState();

  /* three dot STATE*/
  const [isPopupVisible, setPopupVisible] = useState(null);
  const [trackDetailsForShare, setTrackDetailsForShare] = useState(null);
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);
  const { playingAlbumId, setPlayingAlbumId } = useContext(PlayingAlbumIdContext);
  const { playingShuffledAlbumId, setPlayingShuffledAlbumId } = useContext(PlayingShuffledAlbumIdContext);
  const [isMobileView, setIsMobileView] = useState(false);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 991);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    favoriteList();
    favoritePlayList();
    EbookfavoriteList();
    EbookSeriesfavoritePlayList();
    getDownloadedEbooks();
    userPlayListFun();
    userPlayListFunAudio();
    GetFavoriteTracks()
    albumfavouriteList()
  }, []);



  //playlist start
  //playlist
  const openModal = (id) => {
    setAddToPlaylistFlagFromFavorites(true)
    setIsOpen(true);
    setCurrentlyPlaylingVidId(id);
  };
  const openCreatedPlayListModal = () => {
    setShowCreatePlayListModel(true);
  };
  const closeCreatedPlayListModal = () => {
    setShowCreatePlayListModel(false);
  };
  const validateInput = (text) => {
    if (/^\s+/.test(text)) {
      return false;
    }

    const emojiRegexs = emojiRegex; // your emoji regex here;
    if (emojiRegexs.test(text)) {
      return false;
    }

    if (text.length > 35) {
      return false;
    }

    return true;
  };

  const handlePlaylistNameChange = (event) => {
    const inputValue = event.target.value;
    if (validateInput(inputValue)) {
      setPlaylistName(inputValue);
      setIsValidPlaylistName(true);
      setIsValid(true);
    } else {
      setIsValidPlaylistName(false);
      setIsValid(false);
    }
  };
  const handleNoteChange = (event) => {
    const inputValue = event.target.value;
    if (validateInput(inputValue, true)) {
      setNote(inputValue);
      setIsValidNote(true);
    } else {
      setIsValidNote(false);
    }
  };
  const validateForm = () => {
    if (playlistName == "") {
      return false;
    }
    return true;
  };
  const validateNoteForm = () => {
    if (note == "" || noteTitle == "") {
      return false;
    }
    return true;
  };
  const handleSubmit = async (event) => {
    const valid = validateForm();
    var params = {}
    if (valid) {
      if (event === 'bhaktiPad') {
        params = {
          playListName: playlistName,
          contentType: 'YOUTUBE_TRACK'
        };
      }
      else {
        params = {
          playListName: playlistName,
          contentType: 'VIDEO'
        };
      }
      setPlaylistBtnLding(true);
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.CREATEUSERPLAYLIST}`,
          params
        );
        if (data) {

          if (event === 'videos')
            navigate("/myCreatePlayListVideoAddBtnSuggested", {
              state: { id: data.id, title: data.playListName },
            });
          else {
            if(addToPlaylistFlagFromFavorites){
              setShowCreatePlayListModel(false);
              setPlaylistName(null);
              setRefresh(!refresh);
              setPlaylistBtnLding(false);
              openModal(currentlyPlaylingVidId);
              fetchUserPlaylistData();
              setAddToPlaylistFlagFromFavorites(false)
            }
            else{
              navigate("/myCreatePlayListTrackAddBtnSuggested")
            }
          }

          // setPlaylistBtnLding(false);
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
          // setPlaylistBtnLding(false);
        }
      } catch (err) {
        setShowCreatePlayListModel(false);
        closeCreatedPlayListModal()
        setRefresh(!refresh);
        setBtnLoading(false);
        // setPlaylistBtnLding(false);

      }
    } else {
      setIsValid(true);
    }

    // setIsOpen(false);
  };
 
  async function getSelectedPlaylistData() {
    if (auth) {
      try {
        setIsLoading(true);
        const { data } = await apiCall(
          "GET",
          `${ApiEndPoint.GETCHECKEDCHECKBOXFORAUDIO}/${currentlyPlaylingVidId}`
        );
        if (data.length > 0) {
          setIsLoading(false);
          setPreviouslySelectedUserPlaylistArray(data);
        } else {
          setPreviouslySelectedUserPlaylistArray([]);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }
  const fetchUserPlaylistData = () => {
    userPlayListFun(1);
  };
  async function userPlayListFun(fetchPage = 0) {
    if (auth) {
      try {
        const params = {
          searchValue: "",
          sortColumn: "A_TO_Z",
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 8,
          sortDirection: "",
        };
        setIsLoading(true);
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLISTFORAUDIO}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setUserPlayList(fetchPage ? data : (prev) => [...prev, ...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
          setScrollHashMap(false);
        }
      } catch (err) {
        setIsLoading(false);
        // setScrollLast(false);
      }
    }
  }
  async function addVideoAgainstPlaylist() {
    if (auth) {
      if (
        currentlySelectedUserPlaylistArray.length > 0 ||
        unselectedPlaylistArray.length > 0
      ) {
        try {
          const params = {
            videoId: currentlyPlaylingVidId,
            selected: currentlySelectedUserPlaylistArray,
            unSelected: unselectedPlaylistArray,
            contentType: "YOUTUBE_TRACK",
          };
          setIsLoading(true);
          const { status } = await apiCall(
            "POST",
            `${ApiEndPoint.ADDAUDIOAGAINSTMULTIPLEPLAYLIST}`,
            params
          );
          if (status === 200) {
            setIsLoading(false);
            toast.success("Added successfully");
            closeModal();
          } else {
            setIsLoading(false);
            toast.error("Something went wrong , try again");
          }
        } catch (err) {
          setIsLoading(false);
        }
      }
    }
  }
  const handleCheckboxChange = (event) => {
    const playlistId = parseInt(event.target.dataset.playlistId);
    const checked = event.target.checked;
    !isPlaylistSelected(playlistId)
      ? setCurrentlylySelectedUserPlaylistArray((prevSelectedPlaylist) => {
        let updatedSelectedPlaylist = [...prevSelectedPlaylist];
        if (checked) {
          if (
            !updatedSelectedPlaylist.some(
              (playlist) => playlist?.id === playlistId
            )
          ) {
            updatedSelectedPlaylist.push({ id: playlistId });
          }
        } else {
          updatedSelectedPlaylist = updatedSelectedPlaylist.filter(
            (playlist) => playlist.id !== playlistId
          );
        }
        return updatedSelectedPlaylist;
      })
      : removeFromExisting(playlistId);
  };
  const removeFromExisting = (playlistId) => {
    if (
      previouslySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updatedExistingPlaylist =
        previouslySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setPreviouslySelectedUserPlaylistArray(updatedExistingPlaylist);
      setUnselectedPlaylistArray((prev) => {
        return [...prev, { id: playlistId }];
      });
    } else if (
      currentlySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updateSelectedPlaylistData =
        currentlySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setCurrentlylySelectedUserPlaylistArray(updateSelectedPlaylistData);
    } else {
      return;
    }
  };
  const isPlaylistSelected = (playlistId) => {
    return (
      previouslySelectedUserPlaylistArray.some(
        (item) => item?.id == playlistId
      ) ||
      currentlySelectedUserPlaylistArray.some((item) => item?.id === playlistId)
    );
  };
  const fetchMoreUserPlaylistData = () => {
    userPlayListFun();
  };
  const createPlaylist = () => {
    setIsOpen(false);
    setShowCreatePlayListModel(true);
  };
  const closeModal = () => {
    setPreviouslySelectedUserPlaylistArray([]);
    setCurrentlylySelectedUserPlaylistArray([]);
    setUnselectedPlaylistArray([]);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      fetchUserPlaylistData();
      getSelectedPlaylistData();
    }
  }, [isOpen]);
  //playlist end
  const favoriteList = async () => {
    try {
      const params = {
        searchValue: null,
        sortColumn: "LAST_MODIFIED_DATE",
        pageNo: 0,
        pageSize: 10,
      };
      !favoritesVideoList.length > 0 && setIsLoading(true);
      const { data } = await apiCall("POST", ApiEndPoint.MYFAVOURITES, params);
      if (data?.length > 0) {
        setIsLoading(false);
        setFavoritesVideoList(RemoveDuplicates(data));
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  const albumfavouriteList = async () => {
    try {
      const params = {
        searchValue: null,
        sortColumn: "PREVIOUSLY_ADDED",
        pageNo: 0,
        pageSize: 10,
      };
      !albumFavList.length > 0 && setIsLoading(true);
      const { data } = await apiCall("POST", ApiEndPoint.GETALLFAVORITEALBUMS, params);
      if (data?.content.length > 0) {
        setIsLoading(false);
        setAlbumFavList(RemoveDuplicates(data?.content));
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  async function getDownloadedEbooks() {
    // setIsLoading(true)
    try {
      const params = {
        searchValue: null,
        sortColumn: "LAST_MODIFIED_DATE",
        pageNo: 0,
        pageSize: 8,
      };
      const { data } = await apiCall(
        "POST",
        `${ApiEndPoint.GETDOWNLOADEBOOK}`,
        params
      );
      // console.log(data);
      if (data.data.length > 0) {
        setGetDownloadedEbook(RemoveDuplicates(data.data));
      } else {
      }
    } catch (err) { }
  }
  // async function userPlayListFun() {
  //   if (auth) {
  //     try {
  //       const params = {
  //         searchValue: '',
  //         sortColumn: "LAST_MODIFIED_DATE",
  //         pageNo: 0,
  //         pageSize: 8,
  //       };
  //       const { data } = await apiCall(
  //         "POST",
  //         `${ApiEndPoint.USERPLAYLISTFORAUDIO}`,
  //         params
  //       );
  //       if (data.length > 0) {
  //         setIsLoading(false);
  //         // setUserPlayList(fetchPage?data:prev=>[...prev,...data]);
  //         setUserPlayList(RemoveDuplicates(data));
  //       } else {
  //         setUserPlayList([]);
  //       }
  //     } catch (err) {
  //       setIsLoading(false);
  //     }
  //   }
  // }
  async function userPlayListFunAudio(fetchPage = 0) {
    if (auth) {
      try {
        const params = {
          searchValue: '',
          sortColumn: "A_TO_Z",
          pageNo: 0,
          pageSize: 8,
          sortDirection: ''
        };
        setIsLoading(true);
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLISTFORAUDIO}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setUserPlayList(RemoveDuplicates(data));
        } else {
          setIsLoading(false);
          setUserPlayList([]);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }
  const favoritePlayList = async () => {
    try {
      const params = {
        searchValue: null,
        sortColumn: "LAST_MODIFIED_DATE",
        pageNo: 0,
        pageSize: 3,
      };
      !favoritePlayListData.length > 0 && setIsLoading(true);
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.MYFAVOURITEPLAYLISTSHOW,
        params
      );
      if (data && data?.length > 0) {
        setIsLoading(false);
        setFavoritePlayListData(RemoveDuplicates(data));
      } else {
        setFavoritePlayListData(data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  const GetFavoriteTracks = async () => {
    try {
      const body = {
        searchValue: null,
        sortColumn: "LAST_MODIFIED_DATE",
        pageNo: 0,
        pageSize: 5,
        sortDirection: ""
      };
      !audiosFavoriteList?.length > 0 && setIsLoading(true);
      const res = await apiCall(
        "POST",
        ApiEndPoint.GETFAVORITETRACKS,
        body
      );
      const data = res.data.content;
      // console.log(data)
      if (data?.length > 0) {
        setIsLoading(false);
        setAudiosFavoriteList(RemoveDuplicates(data));
      } else {
        setIsLoading(false);
        setAudiosFavoriteList(data);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  const EbookfavoriteList = async () => {
    try {
      const params = {
        searchValue: null,
        sortColumn: "LAST_MODIFIED_DATE",
        pageNo: 0,
        pageSize: 5,
      };
      !eBooksFavoriteList.length > 0 && setIsLoading(true);
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.EBOOKSFAVOURITELIST,
        params
      );
      if (data?.length > 0) {
        setIsLoading(false);
        setEBooksFavoriteList(RemoveDuplicates(data));
      } else {
        setIsLoading(false);
        setEBooksFavoriteList(data);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  const EbookSeriesfavoritePlayList = async () => {
    try {
      const params = {
        searchValue: null,
        sortColumn: "LAST_MODIFIED_DATE",
        pageNo: 0,
        pageSize: 5,
      };
      !eBookSeriesFavoriteList?.length > 0 && setIsLoading(true);
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.EBOOKSERIESFAVOURITELIST,
        params
      );
      if (data?.length > 0) {
        setIsLoading(false);
        setEBookSeriesFavoriteList(RemoveDuplicates(data));
      } else {
        setIsLoading(false);
        setEBookSeriesFavoriteList(data);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const addFavorite = async (item, index) => {
    if (auth) {
      const filteredFavorite = favoritesVideoList?.filter(
        (x) => x.id !== item.id
      );
      setFavoritesVideoList(filteredFavorite);
      try {
        const { data } = await apiCall("POST", `/my-favourites/${item.id}`);
        if (data) {
          setRefresh(!refresh);
          if (filteredFavorite.length <= 1) {
            favoriteList();
          }
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };
  const videoWatchLater = async (item, index) => {
    if (auth) {
      favoritesVideoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.VIDEOWATCHLATER}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };
  const RemoveWatchLaterVideo = async (item, index) => {
    if (auth) {
      favoritesVideoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };
  async function PlaylistWatchLater(item, index) {
    try {
      if (auth) {
        favoritePlayListData[index].watchLater = item.watchLater ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTWATCHLATER}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }
  async function PlaylistWatchLaterRemove(item, index) {
    try {
      if (auth) {
        favoritePlayListData[index].watchLater = item.watchLater ? false : true;
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.PLAYLISTWATCHLATERREMOVE}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }
  async function myFavouritePlaylistRemove(item, index) {
    try {
      if (auth) {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.MYFAVOURITEPLAYLISTREMOVE}/${item.id}`
        );
        if (data.length > 0) {
          const removeWatchLatedVieo = favoritePlayListData.filter(
            (x) => x.id != item.id
          );
          setFavoritePlayListData(removeWatchLatedVieo);
          if (removeWatchLatedVieo.length <= 1) {
            favoritePlayList();
          }
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }
  async function myFavouriteAudiosRemove(item, index) {
    try {
      if (auth) {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.TRACKSFAVORITEDELETE}/${item.trackId}`
        );
        if (data.length > 0) {
          const removeAudios = audiosFavoriteList.filter((x) => x.id != item.id);
          setAudiosFavoriteList(removeAudios);
          if (removeAudios.length <= 1) {
            GetFavoriteTracks();
          }
          setRefresh(!refresh);
          setIsLoading(false);
          // console.log(removeAudios);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }
  async function myFavouriteEbooksRemove(item, index) {
    try {
      if (auth) {
        const { data } = await apiCall(
          "DELETE",
          `${item.contentType == "Transcript"
            ? ApiEndPoint.DELETEFAVOURITETRANSCRIPT
            : item.contentType == "Patra"
              ? ApiEndPoint.DELETEFAVOURITEPATRA
              : ApiEndPoint.DELETEFAVOURITE
          }/${item.contentId}`
        );
        if (data.length > 0) {
          const removeEbooks = eBooksFavoriteList.filter(
            (x) => x.id != item.id
          );
          setEBooksFavoriteList(removeEbooks);
          if (removeEbooks.length <= 1) {
            EbookfavoriteList();
          }
          setRefresh(!refresh);
          setIsLoading(false);
          // console.log(removeEbooks);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }
  async function myFavouriteEbookSeriesRemove(item, index) {
    try {
      if (auth) {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.MYFAVOURITEREMOVEEBOOKSERIES}/${item.id}`
        );
        if (data.length > 0) {
          const removeEbooks = eBookSeriesFavoriteList.filter(
            (x) => x.contentId != item.contentId
          );
          setEBookSeriesFavoriteList(removeEbooks);
          if (removeEbooks.length <= 1) {
            EbookSeriesfavoritePlayList();
          }
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }
  const individualPlayListNav = (item, type) => {
    navigate("/individualPlayList", {
      state: { playListDetails: item, playListType: type },
    });
    watchLaterSavePlayListFun(item.id);
  };
  async function watchLaterSavePlayListFun(id) {
    // setIsLoading(true)
    if (auth) {
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTWATCHLATERSAVE}/${id}`
        );
        if (data.length > 0) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    const updateArray = activetabs?.map((item) => {
      if (item.page === "MySpace") {
        return {
          ...item,
          active: newValue,
        };
      } else {
        return {
          ...item,
          active: "videos",
        };
      }
    });
    setActiveTabs(updateArray);
  };

  const handleTabChangeForCreatePlaylist = (event, newValue) => {
    setTabValueForCreatePlaylist(newValue);
    const updateArray = activetabs?.map((item) => {
      if (item.page === "MySpace") {
        return {
          ...item,
          active: newValue,
        };
      } else {
        return {
          ...item,
          active: "videos",
        };
      }
    });
    setActiveTabs(updateArray);
  };
  const handleTabChangePlaylist = (event, newValue) => {
    setPlaylistTab(newValue);
  };
  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "#FFB200",
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
    },
    "&.MuiTab-root": {
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
      minWidth: "auto",
    },
  });
  const handleReadOrPlayClick = (contentUrl) => {
    if (tabValue === "ebookSeries") {
      navigate(`/eBookSeries/${contentUrl}`);
    } else {
      const newTabUrl = `/fileViewer?contentUrl=${encodeURIComponent(
        contentUrl
      )}`;
      window.open(newTabUrl, "_blank");
    }
  };
  const handleOpenCreatePlaylist = () => {
    navigate("/createdPlaylist", { state: { viewMore: true } });
  };
  const handleDownloadClick = async (item, index) => {
    try {
      if (auth) {
        const params = {
          ebooksContents: item.contentType === "Ebook" ? item.contentId : "",
          patraContents: item.contentType === "Patra" ? item.contentId : "",
          transcriptContent:
            item.contentType === "Transcript" ? item.contentId : "",
        };
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.EBOOKDOWNLOAD}`,
          params
        );
        if (data.status == 200) {
          fileDownload(item.contentUrl, index);
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  };
  function getFileExtension(url) {
    const parts = url.split(".");
    return parts[parts.length - 1];
  }
  const [fileDownloadLoader, setFileDownloadLoader] = useState(null);
  const fileDownload = async (contentUrl, index) => {
    try {
      setFileDownloadLoader(index);
      let fileUrl = "";
      let fileExtension = getFileExtension(contentUrl);
      switch (fileExtension) {
        case "epub":
        case "pdf":
        case "mp3":
          fileUrl = contentUrl;
          break;
        default:
          console.error("Invalid file type:", fileExtension);
          return;
      }
      const response = await fetch(fileUrl);
      if (!response.ok) {
        setFileDownloadLoader(null);
        throw new Error(`Failed to download file (HTTP ${response.status})`);
      }
      let fileName = contentUrl?.substring(contentUrl.lastIndexOf("/") + 1);
      fileName = fileName?.replace(
        /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/,
        ""
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setFileDownloadLoader(null);
      // Display a success toast message
      toast.success("File downloaded successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error downloading the file:", error);
      // Display an error toast message
      toast.error("Error downloading the file. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const questionHistory = () => {
    navigate("/questionHistory", { state: { viewMore: true } });
  };


  const handleTitleClick = (item) => {
    setSingleTrack([])
    setTrack([])
    setPlayNextTrack([])
    setPlayingShuffledAlbumId(null)
    setPlayingAlbumId(null)
    setPlayingAlbumId(null);
    let trackList = [];
    trackList[0] = item;
    setTrack(trackList);
    setIsMusicPlayerVisible(true);
  };
  

  const handleFavoriteAlbums = async (item, type, action) => {
    try {
      const apiUrl = type === 'albums'
        ? action === 'add'
          ? `${apiEndPoints.ALBUMSFAVORITE}/${item.id}`
          : `${apiEndPoints.ALBUMSFAVORITEDELETE}/${item.id}`
        : action === 'add'
          ? `${apiEndPoints.TRACKSFAVORITE}/${item.id}`
          : `${apiEndPoints.TRACKSFAVORITEDELETE}/${item.id}`;
  
      const method = action === 'add' ? 'POST' : 'DELETE';
  
      const updatedList = albumFavList.map((album) =>
        album.id === item.id ? { ...album, isFavourite: action === 'add' } : album
      );
  
      setAlbumFavList(updatedList);
  
      const response = await apiCall(method, apiUrl);
  
      if (response) {
        const favoriteAlbums = updatedList.filter((x) => x.isFavourite);
        setAlbumFavList(favoriteAlbums);
  
        if (favoriteAlbums.length <= 1) {
          albumfavouriteList();
        }
      } else {
        const revertedList = albumFavList.map((album) =>
          album.id === item.id ? { ...album, isFavourite: !album.isFavourite } : album
        );
        setAlbumFavList(revertedList);
      }
    } catch (error) {
      console.error("Error updating favorite albums:", error);
  
      const revertedList = albumFavList.map((album) =>
        album.id === item.id ? { ...album, isFavourite: !album.isFavourite } : album
      );
      setAlbumFavList(revertedList);
    }
  };

  async function myFavoriteTracksDelete(item, index) {
    try {
      if (auth) {
        const updatedList = [...audiosFavoriteList];
        updatedList[index].isFavourite = !item.isFavourite;
  
        setAudiosFavoriteList(updatedList);
  
        const { data } = await apiCall("DELETE", `${apiEndPoints.TRACKSFAVORITEDELETE}/${item.id}`);
  
        if (data.length > 0) {
          const favoriteTracks = updatedList.filter((x) => x.isFavourite);
          setAudiosFavoriteList(favoriteTracks);
  
          if (favoriteTracks.length <= 1) {
            GetFavoriteTracks();
          }
        } else {
          updatedList[index].isFavourite = !updatedList[index].isFavourite;
          setAudiosFavoriteList(updatedList);
        }
  
        setRefresh((prev) => !prev);
      }
    } catch (err) {
      console.error("Error deleting track:", err);
      setRefresh((prev) => !prev);
    } finally {
      setIsLoading(false);
    }
  }
  

  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }


  const formatDuration = (duration) => {

    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setTimeout(() => {
        setPopupVisible(null);
      }, 500);
    }
  };

  // const inputRef = useRef(null);

  // useEffect(() => {

  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [playlistName]);

  useEffect(() => {
    if (isPopupVisible !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupVisible]);

  /* three dot METHODS START*/
  const togglePopup = (id) => {
    setPopupVisible(isPopupVisible === id ? null : id);
  };


  // const handlePlayNext = (trackDetails) => {
  //   if (!isMusicPlayerVisible) {
  //     toast.warn("No Bhakti pad is playing.");
  //     setPopupVisible(null);
  //     return;
  //   }

  //   if (trackDetails) {
  //     const newTrackList = [...track];
  //     newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
  //     setTrack(newTrackList);
  //   }

  //   setPopupVisible(null);
  //   toast.success("Bhakti Pad will Play Next.");
  // };

  const handlePlayNext = (trackDetails) => {
    if (!isMusicPlayerVisible) {
      toast.warn("No Bhakti pad is playing.");
      setPopupVisible(null);
      return;
    }
    // const trackDetails = musicData?.find(track => track?.id == id);
    if (trackDetails) {
      let newTrackList = [...track];
      const existingTrackIndex = newTrackList.findIndex(track => track.id === trackDetails?.id);
      if (existingTrackIndex !== -1) {
        newTrackList.splice(existingTrackIndex, 1);
      }
      newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
      setTrack(newTrackList);
      dispatch(addTrack(newTrackList));
      toast.success("Bhakti Pad will Play Next.");
    }
    setPopupVisible(null);
  };

  const handleCopyLink = (id) => {
    // console.log(`Copy link for track: ${id}`);
    const trackDetails = audiosFavoriteList?.find(track => track?.id == id);
    // console.log('trackDetails',trackDetails?.audioAlbum?.id);
    setTrackDetailsForShare(trackDetails?.audioAlbum?.id)
    // setPopupVisible(null);
  };

  /* three dot METHODS ENDS*/
  function takeSubscription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please Subscribe to Paid Yearly (12 months) plan to play this Bhakti Pad`}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                      alt="img"
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                  // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }

  return (
    <div className={`my-space${isMusicPlayerVisible ? " ytPlayerOpen" : ""}`}>
      {/* <div className="my-space"> */}
      <p class="mainTitle">My Space</p>
      <div className="title-section-div">
        <p className="title-section">My Favourites</p>
        <p className="title-view-more" onClick={() => navigate("/myFavorites")}>
          View More
        </p>
      </div>
      {/*----- videos, playlists and ebook Tab Bar -----*/}
      <div className="py-pl-prr-5">
        <Box className="my-space-tab-a-center">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#FFB200",
                minWidth: "auto",
              },
            }}
            aria-label="secondary tabs example"
          >
            <StyledTab value="videos" label="Satsang" />
            <StyledTab value="playlists" label="Satsang Playlist" />
            <StyledTab value="bhaktiPad" label="Bhakti Pad" />
            <StyledTab value="albums" label="Albums" />
            <StyledTab value="ebook" label="E-BOOKS" />
            <StyledTab value="ebookSeries" label="E-BOOK Series" />

          </Tabs>
        </Box>
      </div>



      {/*----- Common Carousal Start -----*/}
      {tabValue == "videos" && (
        <div className="container watch-later my-space-mt-40">
          {favoritesVideoList && favoritesVideoList.length > 0 ? (
            <CommonCarousal
              addFavorite={(item, index) => addFavorite(item, index)}
              carousalVideoList={favoritesVideoList}
              videoWatchLater={(item, index) =>
                item?.watchLater
                  ? RemoveWatchLaterVideo(item, index)
                  : videoWatchLater(item, index)
              }
              type="RecentlyWatched"
            />
          ) : (
            <div className="my-space-empty-card-wrap-outer">
              <div className="my-space-empty-card-wrap">
                <span className="my-space-empty-msg">
                  {
                    "You haven't added any videos to your favourites yet. To add a video to favorites, simply click on the 'Add to Favorites' icon"
                  }
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {/*----- Favorite playlist -----*/}
      {tabValue == "playlists" && (
        <div className="container watch-later my-space-mt-40">
          {favoritePlayListData && favoritePlayListData?.length > 0 ? (
            <div className="my-space-col-count-3 custom-row my-space-playlists">
              {favoritePlayListData?.slice(0, 3)?.map((item, index) => (
                <div className="custom-col-4 custom-col">
                  <div key={index} className="my-space-card-wrap">
                    <div className="my-space-inside-wrap">
                      <div
                        className="my-space-list-image"
                        onClick={() => {
                          individualPlayListNav(item, "admin");
                        }}
                      >
                        <img
                          className={`my-space-img-banner`}
                          src={`${GLOBAL_URL}/system/playlist/thumbnail/${item.id}`}
                          key={item.id}
                          alt="Card Image"
                        />
                        <img
                          className="my-space-play-icon"
                          src={AllImages?.PlayIcon}
                          alt="play Icon"
                          title="Play Icon"
                        />
                      </div>
                      <div className="my-space-card-content-item">
                        <div className="my-space-video-content">
                          <div>
                            <p className="my-space-title-text f-poppins">
                              {item.playlistName}
                            </p>
                            <div
                              style={{ gap: "10px" }}
                              className="general-textDetails general-text-hrs f-poppins"
                            >
                              <p className="general-text-hrs">
                                {item.videoDuration}{" "}
                                {item.videoDuration ? "hrs" : "0 hrs"}
                              </p>
                              <p className="general-text-hrs">•</p>
                              <p className="general-text-hrs">
                                {item.noOfVideos}{" "}
                                {item.noOfVideos ? "videos" : "0 videos"}
                              </p>
                            </div>
                          </div>
                          <div className="my-space-mt-10 my-space-play-list-ta-right">
                            <img
                              onClick={() =>
                                item.watchLater
                                  ? PlaylistWatchLaterRemove(item, index)
                                  : PlaylistWatchLater(item, index)
                              }
                              src={
                                item.watchLater
                                  ? AllImages.SelectedTimer
                                  : AllImages?.Timer
                              }
                              alt="Timer"
                              title="Timer"
                            ></img>
                            <img
                              onClick={() =>
                                myFavouritePlaylistRemove(item, index)
                              }
                              src={
                                item.favourite
                                  ? AllImages.Favorite_sel
                                  : AllImages?.Fav
                              }
                              alt="Favourite"
                              title="Favourite"
                            ></img>
                            {/* <RWebShare
                          data={{
                            // text: "Like humans, flamingos make friends for life",
                            url: `${VideoPlaylistShareUrl}/PlayListShared/${item.id}`,
                            title: "Video Share",
                          }}
                          onClick={() => console.log('share')}
                        >
                          <img src={AllImages.Copy}></img>
                        </RWebShare> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="my-space-empty-card-wrap-outer">
              <div className="my-space-empty-card-wrap">
                <span className="my-space-empty-msg">
                  {
                    "You haven't added any playlist to your favourites yet. To add a playlist to favorites, simply click on the 'Add to Favorites' icon"
                  }
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {/*----- Favorite ebooks -----*/}
      {tabValue == "ebook" && (
        <div className="container watch-later my-space-mt-40">
          {eBooksFavoriteList && eBooksFavoriteList?.length > 0 ? (
            <div className="my-space-col-count-3 custom-row">
              {eBooksFavoriteList?.slice(0, 5)?.map((item, index) => (
                <div className="custom-col-4 custom-col my-space-ebook">
                  <div key={index} className="my-space-card-wrap">
                    <div className="my-space-inside-wrap">
                      <div className="my-space-list-image">
                        <img
                          className="my-space-img-banner"
                          src={item?.thumbnailImageUrl}
                          key={item.id}
                          alt="Card Image"
                        />
                      </div>
                      <div className="my-space-card-content-item">
                        <div className="my-space-video-content">
                          <div>
                            <p className="my-space-title-text f-poppins">
                              {item.title}
                            </p>
                            <div
                              style={{ gap: "10px" }}
                              className="ebook-textDetails ebook-text-hrs f-poppins"
                            >
                              <p className="ebook-text-hrs">
                                {item?.pageCount} pages
                              </p>
                            </div>
                          </div>
                          <div className="download-ebook-like-icon-div">
                            <div className="d-flex align-center">
                              <RWebShare
                                data={{
                                  url: `${VideoPlaylistShareUrl}/fileViewer?contentUrl=${encodeURIComponent(
                                    item?.contentUrl?.replace(/ /g, "%20")
                                  )}`,
                                  title: "Ebook Share",
                                }}
                                onClick={() => console.log("share")}
                              >
                                <div className="copyTooltip">
                                  <img
                                    src={AllImages.Copy}
                                    alt="Copy"
                                    title="Copy"
                                  ></img>
                                  <span className="tooltiptext">{"Copy"}</span>
                                </div>
                              </RWebShare>
                              <img
                                onClick={() =>
                                  myFavouriteEbooksRemove(item, index)
                                }
                                src={AllImages.Favorite_sel}
                                alt="Favourite"
                                title="Favourite"
                              ></img>
                              {fileDownloadLoader == index ? (
                                <img
                                  src={loader}
                                  alt="loading..."
                                  style={{ height: 20, width: 20 }}
                                />
                              ) : (
                                <>
                                  <img
                                    onClick={() =>
                                      handleDownloadClick(item, index)
                                    }
                                    src={AllImages.Download}
                                    alt="Download"
                                    title="Download"
                                  ></img>
                                  <span className="tooltiptext">
                                    {"Download"}
                                  </span>
                                </>
                              )}
                            </div>
                            <p
                              onClick={() =>
                                handleReadOrPlayClick(item.contentUrl)
                              }
                              className="download-ebook-read"
                            >
                              Read
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="my-space-empty-card-wrap-outer">
              <div className="my-space-empty-card-wrap">
                <span className="my-space-empty-msg">
                  {
                    "You haven't added any ebooks to your favourites yet. To add a ebooks to favorites, simply click on the 'Add to Favorites' icon"
                  }
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {tabValue == "ebookSeries" && (
        <div className="container watch-later my-space-mt-40">
          {eBookSeriesFavoriteList && eBookSeriesFavoriteList?.length > 0 ? (
            <div className="my-space-col-count-3 custom-row">
              {eBookSeriesFavoriteList?.map((item, index) => (
                <div className="custom-col-4 custom-col my-space-ebook">
                  <div key={index} className="my-space-card-wrap">
                    <div className="my-space-inside-wrap">
                      <div className="my-space-list-image">
                        <img
                          className="my-space-img-banner"
                          src={item?.thumbnail}
                          key={item.id}
                          alt="Card Image"
                        />
                      </div>
                      <div className="my-space-card-content-item">
                        <div className="my-space-video-content">
                          <div>
                            <p className="my-space-title-text f-poppins">
                              {item.playlistName}
                            </p>
                            <div
                              style={{ gap: "10px" }}
                              className="ebook-textDetails ebook-text-hrs f-poppins"
                            >
                              {/* <p className='ebook-text-hrs'>200 pages</p> */}
                              {/* <img className='dot' src={AllImages?.Dot} alt="dot" title="dot"></img> */}
                              <p className="ebook-text-hrs">
                                {item?.ebookCount ? item.ebookCount : 0} E-Books
                              </p>
                              {/* {!item?.ebookCount && (
                                        <p className="ebook-text-hrs">
                                          &nbsp;
                                        </p>
                                      )} */}
                              {/* <p className="ebook-text-hrs">•</p>
                                {item.publishDateTime ? (
                                  <p className="ebook-text-hrs">
                                    <p className='ebook-text-hrs'>{DateTimeAgo(item.publishDateTime)}</p>
                                  </p>
                                  ): (
                                  <p className="ebook-text-hrs">
                                    &nbsp;
                                  </p>
                                )} */}
                            </div>
                          </div>
                          <div className="download-ebook-like-icon-div">
                            <div className="d-flex align-center">
                              <RWebShare
                                data={{
                                  // text: "Like humans, flamingos make friends for life",
                                  url: `${VideoPlaylistShareUrl}/eBookSeries/${item.id}`,
                                  title: "Ebook Series Share",
                                }}
                                onClick={() => console.log("share")}
                              >
                                <div className="copyTooltip">
                                  <img
                                    src={AllImages.Copy}
                                    alt="Copy"
                                    title="Copy"
                                  ></img>
                                  {/* <span className="tooltiptext">
                                {!item.myFavourite
                                  ? "Mark Favorite"
                                  : "Unfavorite"}
                              </span> */}
                                  <span className="tooltiptext">{"Copy"}</span>
                                </div>
                              </RWebShare>
                              <img
                                onClick={() =>
                                  myFavouriteEbookSeriesRemove(item, index)
                                }
                                src={AllImages.Favorite_sel}
                                alt="Favourite"
                                title="Favourite"
                              ></img>
                            </div>
                            <p
                              onClick={() => handleReadOrPlayClick(item.id)}
                              className="download-ebook-read"
                            >
                              Open
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="my-space-empty-card-wrap-outer">
              <div className="my-space-empty-card-wrap">
                <span className="my-space-empty-msg">
                  {
                    "You haven't added any ebook series to your favourites yet. To add a ebooks to favorites, simply click on the 'Add to Favorites' icon"
                  }
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      
      {/*----- Common Carousal Start -----*/}
      {tabValue === "bhaktiPad" && (
        <div className="container watch-later my-space-mt-40 popular-bhaktiPads-page">
          {audiosFavoriteList && audiosFavoriteList.length > 0 ? (
            <Table responsive striped>
              <thead class="mobHide">
                <tr>
                  <th>No.</th>
                  <th>Title</th>
                  <th class="none">Album</th>
                  <th class="none">Duration</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody class="mobHide">


                {isOpen && (
                  <Dialog
                    open={openModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll={"paper"}
                  >
                    <DialogContent
                      // ref={dialogContentRef}
                      style={{
                        overflowY: "auto",
                        overflowX: "hidden",
                        maxHeight: "320px", // Adjust the maximum height as needed
                        margin: 5,
                      }}
                    // onScroll={handlePlaylistScroll}
                    >
                      <div className="add-my-playlist-modal-content">
                        <div className="add-my-playlist-modal-d-flex modal-mb-5">
                          <p className="add-my-playlist-modal-msg-text">
                            {"Add to My Playlists"}
                          </p>
                          <span
                            className="add-my-playlist-modal-close-button"
                            onClick={closeModal}
                          >
                            &times;
                          </span>
                        </div>
                        <div>
                          {userPlayList &&
                            userPlayList?.map((item, index) => (
                              <div
                                key={item.id}
                                className="add-my-playlist-modal-d-flex modal-mt-15"
                              >
                                <input
                                  type="checkbox"
                                  id={`checkbox_${item.id}`}
                                  name={`checkbox_${item.id}`}
                                  data-playlist-id={item.id}
                                  checked={isPlaylistSelected(item.id)}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="add-my-playlist-checkbox-text"
                                  htmlFor={`checkbox_${item.id}`}
                                >
                                  {item.playListName}
                                </label>
                              </div>
                            ))}


                          {userPlayList && userPlayList.length > 6 && scrollHashMap && (
                            <p
                              onClick={fetchMoreUserPlaylistData}
                              className="fetch-more-playlist"
                            >
                              {/* {console.log(userPlayList, "ttttttttttt", scrollHashMap)} */}
                              MORE+
                            </p>
                          )}

                        </div>
                        <div className="modal-mt-15" onClick={createPlaylist}>
                          <label className="add-my-playlist-create-playlist">
                            Create Playlist
                          </label>
                        </div>
                      </div>
                      {isLoading && (
                        <div className="loader">
                          <img src={loader} alt="loading..." />
                        </div>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <div className="add-my-playlist-modal-action">
                        <button
                          onClick={closeModal}
                          className="add-my-playlist-modal-cancel-btn"
                        >
                          {"Cancel"}
                        </button>
                        <button
                          className="add-my-playlist-modal-add-btn"
                          onClick={addVideoAgainstPlaylist}
                        >
                          {"confirm"}
                        </button>
                      </div>
                    </DialogActions>
                  </Dialog>
                )}



                {audiosFavoriteList.slice(0, 3)?.map((item, index) => (
                  <tr key={item?.id} class="tableHover">
                    <td>
                      <div class="srNo"
                        onClick={() => { item?.url ? handleTitleClick(item) : takeSubscription(item) }}
                      >
                        <p class="no mobHide">{index + 1}</p>
                        <img src={item?.thumbnailUrl} class="bhaktiImg" alt="img" />
                        {item?.url ?
                          // <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                          <div className="bhaktipadPlayIconTooltip">
                            <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                            <div className="bhaktipadPlayIconTooltipInner">
                              <span className="tooltiptext">
                                {"Play Bhakti Pad"}
                              </span>
                            </div>
                          </div>
                          :
                          <div className="star1 badgestarInvisible tableLock">
                            <div className="LikeCount badgeShow">
                              <i
                                className="fa fa-lock"
                                onClick={() => takeSubscription(item)}
                              ></i>
                            </div>
                            <span className="tooltiptext">
                              {"Subscribe to use this feature"}
                            </span>
                          </div>
                        }
                      </div>
                    </td>
                    <td>
                      <div
                        class="titleSection"
                        onClick={() => { item?.url ? handleTitleClick(item) : takeSubscription(item) }}
                      >
                        {/* <div class="titleSection" onClick={() => navigate('/playlist')}> */}
                        <p class="title">{item.title}</p>
                        <p class="subTitle">{item?.audioAlbum?.title}</p>
                      </div>
                    </td>
                    <td class="none">
                      <p class="album">{item?.audioAlbum?.title}</p>
                    </td>
                    <td class="none">
                      <p class="duration">
                        {formatDuration(item.duration)}
                      </p>
                    </td>
                    <td>
                      <div class="actions">
                        <div className="favTooltip">
                          <img
                            onClick={() => 
                              item?.url ?
                              myFavoriteTracksDelete(item, index)
                              : takeSubscription(item)
                            }
                            src={
                              item.isFavourite
                                ? AllImages.Favorite_sel
                                : AllImages?.Fav
                            }
                            alt="IMG"
                          ></img>
                          <span className="tooltiptext">
                            {!item.isFavourite ? "Mark Favorite" : "Unfavorite"}
                          </span>
                        </div>


                        {/* add to playlist */}
                        <div className="favTooltip">
                          <img
                            className="video-play-mr-10"
                            class="mobHide"
                            alt="img"
                            src={AllImages?.TablePlus}
                            onClick={() => 
                              item?.url ?
                              openModal(item?.id)
                              : takeSubscription(item)
                            }
                          ></img>
                          <span className="tooltiptext">
                            {"Add to My Playlists"}
                          </span>
                        </div>


                        {/* three dots START */}
                        {/* <div key={item.id} ref={popupRef} className="popupOuter">
                          <img
                            src={AllImages?.More}
                            className="moreImg"
                            alt="img"
                            onClick={() => togglePopup(item?.id)}
                          />
                        </div>*/}
                        <div key={item.id} className="popupOuter moreTooltip" ref={popupRef}>
                          <img
                            src={AllImages?.More}
                            className="moreImg"
                            alt="img"
                            onClick={() => 
                              item?.url ?
                              togglePopup(item?.id)
                              :  takeSubscription(item)
                            }
                          //ref={popupRef}
                          />
                          <div className="moreTooltipInner">
                            <span className="tooltiptext">
                              {"View More"}
                            </span>
                          </div>
                        </div>
                      </div>

                      {isPopupVisible === item.id && (
                        !isMobileView ? (
                          <div className="morePopup" ref={popupRef}>
                            <div className="outer" onClick={() => handlePlayNext(item.id)}>
                              <img src={AllImages?.Next} className="more" alt="img" />
                              <p className="label">Play Next</p>
                            </div>
                            <div className="outer" onClick={() => handleCopyLink(item.id)}>
                              <RWebShare
                                data={{
                                  url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                  title: 'Bhakti Pad Share',
                                }}
                                onClick={() => {
                                  handleCopyLink(item.id);
                                  console.log('shares');
                                }}
                              >
                                <div className="copyTooltip">
                                  <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                  <span className="tooltiptext">Copy</span>
                                </div>
                              </RWebShare>
                              <div>
                                <RWebShare
                                  data={{
                                    url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                    title: 'Bhakti Pad Share',
                                  }}
                                  onClick={() => {
                                    handleCopyLink(item.id);
                                    console.log('share');
                                  }}
                                >
                                  <p className="label">Copy Link</p>
                                </RWebShare>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="mobileViewDialogOuter" ref={popupRef}>
                            <Dialog
                              open={togglePopup}
                              className="sortBy mobileViewDialog"
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                              ref={popupRef}
                            >
                              <DialogContent
                                style={{
                                  overflowY: "hidden",
                                  overflowX: "hidden",
                                }}
                                ref={popupRef}
                              >
                                <div className="qu-history-modal-msg-content">
                                  <p className="qu-history-modal-msg-text">{"More Option"}</p>
                                  <span
                                    className="qu-history-modal-close-button"
                                    onClick={togglePopup}
                                  >
                                    &times;
                                  </span>
                                </div>
                                <div class="actionPopupMob" ref={popupRef}>
                                  <div class="outer tabHide" onClick={() => myFavoriteTracksDelete(item, index)} ref={popupRef}>
                                    {/* <img src={AllImages?.FavouriteOutline} class="fav" /> */}
                                    <img
                                      src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Fav}
                                      alt="IMG"
                                      ref={popupRef}
                                    ></img>
                                    <p class="label">Add to Favourites</p>
                                  </div>
                                  <div class="outer tabHide" onClick={() => 
                                    item?.url ?
                                    openModal(item?.id)
                                    : takeSubscription(item)
                                    } ref={popupRef}>
                                    <img
                                      className="plus"
                                      alt="img"
                                      src={AllImages?.TablePlus}
                                    />
                                    <p class="label">Add to Playlist</p>
                                  </div>
                                  <div className="outer" onClick={() => handlePlayNext(item)} ref={popupRef}>
                                    <img src={AllImages?.Next} className="more" alt="img" />
                                    <p className="label">Play Next</p>
                                  </div>
                                  <div className="outer" onClick={() => handleCopyLink(item.id)}>
                                    <RWebShare
                                      data={{
                                        url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                        title: 'Bhakti Pad Share',
                                      }}
                                      onClick={() => {
                                        handleCopyLink(item.id);
                                        console.log('shares');
                                      }}
                                    >
                                      <div className="copyTooltip">
                                        <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                        <span className="tooltiptext">Copy</span>
                                      </div>
                                    </RWebShare>
                                    <div>
                                      <RWebShare
                                        data={{
                                          url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                          title: 'Bhakti Pad Share',
                                        }}
                                        onClick={() => {
                                          handleCopyLink(item.id);
                                          console.log('share');
                                        }}
                                      >
                                        <p className="label">Copy Link</p>
                                      </RWebShare>
                                    </div>
                                  </div>
                                </div>
                              </DialogContent>
                            </Dialog>
                          </div>
                        )
                      )}
                    </td>
                  </tr>
                ))}



              </tbody>
            </Table>
          ) : (
            <div className="my-space-empty-card-wrap-outer">
              <div className="my-space-empty-card-wrap">
                <span className="my-space-empty-msg">
                  You haven't added any Bhakti pad to your favourites yet. To add a Bhakti pad to favorites, simply click on the 'Add to Favorites' icon
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      {tabValue === "albums" && (
        <div className="bhaktiPadsTabBar">
          {albumFavList && albumFavList.length > 0 ? (
            <div class="albumOuter">
             
              {albumFavList.slice(0, 5)?.map((album, index) => (

                <div
                  className="album"
                  key={album?.id}
                >
                  <div className={album?.hasFreeSongs ? "albumTop" : "albumTop albumLockOuter"}>
                    <img src={album.thumbnailUrl ? album.thumbnailUrl : AllImages?.Album5} className="albumImg" alt="Album" />
                    <div className="albumHover">
                      <div className="albumHoverInner">
                        <div className="favTooltip">
                          <img
                            onClick={() => {
                              handleFavoriteAlbums(album, 'albums', album.isFavourite ? 'delete' : 'add')
                            }}
                            src={
                              album.isFavourite
                                ? AllImages.Favorite_sel
                                : AllImages?.Heart
                            }
                            alt="IMG"
                          ></img>
                          <span className="tooltiptext">
                            {!album.isFavourite ? "Mark Favorite" : "Unfavorite"}
                          </span>
                        </div>
                        <div className="favTooltip">
                          <RWebShare
                            data={{
                              url: `${BhaktiPadPlaylistShareUrl}/playlist/${album.id}`,
                              title: "Playlist Share",
                            }}
                            onClick={() => console.log("share")}
                          >
                            <div className="copyTooltip">
                              <img
                                className="cursor"
                                src={AllImages.CopyPNG}
                                alt="Copy"
                                title="Copy"
                              ></img>
                              <span className="tooltiptext">
                                {"Copy"}
                              </span>
                            </div>
                          </RWebShare>
                        </div>
                      </div>
                      <div className="albumInnerPlayIconOuter"
                              onClick={() => { album?.hasFreeSongs ? navigate(`/playlist/${album?.id}`, { state: { playListDetails: album } })  : takeSubscription() }}>
                              {album?.hasFreeSongs ?
                                <div className="bhaktipadPlayIconTooltip">
                                  <img src={AllImages?.PlayIconBig} class="albumInnerPlayIcon" alt="img" />
                                  <div className="bhaktipadPlayIconTooltipInner">
                                    <span className="tooltiptext">
                                      {"Play BhaktiPad Playlist"}
                                    </span>
                                  </div>
                                </div>
                                :
                                <div className="star1 badgestarInvisible albumLock">
                                  <div className="LikeCount badgeShow">
                                    <i
                                      className="fa fa-lock"
                                      onClick={() => takeSubscription(album)}
                                    ></i>
                                  </div>
                                  <span className="tooltiptext">
                                    {"Subscribe to use this feature"}
                                  </span>
                                </div>
                              }
                            </div>
                    </div>
                  </div>
                  <div className="albumBottom"
                    // onClick={() => navigate(`/playlist/${album?.id}`)}
                    onClick={() => { 
                    
                     navigate(`/playlist/${album?.id}`) 
                    
                    }}
                  >
                    <p className="name">{album?.title}</p>
                    <div className="desc">
                      <p class="time">{convertMinutesToHoursAndMinutes(album?.durationTotalMin)} hrs</p>
                      <p className="dot">&nbsp;</p>
                      <p className="count"> {album?.totalActiveTracks} bhakti pads</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="my-space-empty-card-wrap-outer">
              <div className="my-space-empty-card-wrap">
                <span className="my-space-empty-msg">
                  You haven't added any Album to your favourites yet. To add a Album to favorites, simply click on the 'Add to Favorites' icon.
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      {/*----- Empty Favorite Playlist -----*/}
      {/* <div className="my-space-empty-card-wrap">
        <span className='my-space-empty-msg'>{"You haven't added any videos to your favourites yet. To add a video to favorites, simply click on the 'Add to Favorites' icon"}</span>
      </div> */}



      {/*----- Downloaded E-BOOKS Start -----*/}
      <div className="container watch-later my-space-mt-40">
        <div className="title-section-div">
          <p className="title-section">Downloaded E-Books</p>
          {getdownloadedEBook?.length > 5 && (
            <p
              className="title-view-more"
              onClick={() =>
                navigate("/downloadedEbooks", { state: { viewMore: true } })
              }
            >
              View More
            </p>
          )}
        </div>
        <div className="my-space-mlr-10">
          {getdownloadedEBook?.length > 0 ? (
            <DownloadedEbooks />
          ) : (
            <div className="my-space-empty-card-wrap">
              <span className="my-space-empty-msg">
                {"You haven't added any downloaded E-Books yet."}
              </span>
            </div>
          )}
        </div>
      </div>


      {/*----- MY Created Playlists Start -----*/}
      <div className="container watch-later my-space-mt-40">
        <div className="title-section-div">
          <p className="title-section">MY Created Playlists</p>
          <div className="d-flex">
            <p
              className="title-view-more"
              style={{ marginRight: '10px' }}
              onClick={() => setShowCreatePlayListModel(true)}
            >
              {"Create"}
            </p>
            <p
              className="title-view-more"
              onClick={() => handleOpenCreatePlaylist()}
            >
              {"View More"}
            </p>

          </div>
        </div>
        <div className="my-space-mlr-10 my-space-mt-20">
          <CreatedPlaylist />
        </div>
      </div>



      {/*----- MY Created Playlists Start -----*/}
      <div className="container watch-later my-space-mt-40">
        <div className="title-section-div">
          <p className="title-section">Shared Playlists</p>
          <div className="d-flex">
            <p className="title-view-more" onClick={() => navigate("/sharedPlaylist", { state: { viewMore: true } })}
            >
              View More
            </p>
          </div>
        </div>

        <div className="my-space-mlr-10 my-space-mt-20">
          <SharedPlaylist />
        </div>
      </div>


      {/*----- Question History Start -----*/}
      <div className="container watch-later my-space-mt-40">
        <div className="title-section-div">
          <p className="title-section">Question History</p>
          <p className="title-view-more" onClick={() => questionHistory()}>
            View More
          </p>
        </div>
        <div className="my-space-mlr-10">
          <QuestionHistory />
        </div>
        {!questionHistoryList.length > 0 && (
          <div className="my-space-empty-card-wrap">
            <span className="my-space-empty-msg">
              {
                "Question which are asked in the live session it will be appear in the question history."
              }
            </span>
          </div>
        )}
      </div>




      {/*----- Create a Playlist Modal -----*/}
      {showCreatePlayListModel && (
        <Dialog
          open={showCreatePlayListModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="createPlaylistModal"
        >
          <DialogContent>
            <div className="my-create-playlist-modal-content">
              <div className="modal-d-flex">
                <p className="my-created-playlist-modal-msg-text">
                  {"Create a Playlist"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeCreatedPlayListModal()}
                >
                  &times;
                </span>
              </div>
              {/*----- Modal Tab Bar -----*/}
              <div>
                <Box className="my-space-tab-a-center">
                  <Tabs
                    value={tabValueForCreatePlaylist}
                    onChange={handleTabChangeForCreatePlaylist}
                    sx={{
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#FFB200",
                        minWidth: "auto",
                      },
                    }}
                    aria-label="secondary tabs example"
                  >
                    <StyledTab value="videos" label="Satsang" />
                    <StyledTab value="bhaktiPad" label="Bhakti Pad" />
                  </Tabs>
                </Box>
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Playlist Name"}
                </p>
                <input
                  placeholder="Spiritual Playlist"
                  // className={`f-poppins my-create-playlist-modal-search-input ${!isValidPlaylistName ? "invalid" : null}`}
                  className={`f-poppins my-create-playlist-modal-search-input invalid`}
                  type="text"
                  id="playlistName"
                  value={playlistName}
                  onChange={handlePlaylistNameChange}
                // ref={inputRef} 
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="my-created-playlist-modal-action-content">
              <button
                className="my-create-playlist-cancel-btn"
                onClick={() => closeCreatedPlayListModal()}
              >
                {"Cancel"}
              </button>
              <button
                className="my-create-playlist-create-btn"
                onClick={() => handleSubmit(tabValueForCreatePlaylist)}
              // onClick={() => navigate("/myCreatePlayListTrackAddBtnSuggested")}
              >
                {/* {BtnLoading ? ( */}
                {createPlaylistBtnLding ? (
                  <img src={loader} alt="img" style={{ width: 20, height: 20 }} />
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}

    </div>
  );
}
export default MySpace;
